import React from "react";
import { Icon } from "@mui/material";
import MyLogoTwo from '../images/MonoThick.svg';

const Logo = () => (
  <Icon style={{ fontSize: 55 }}>
    <img src={MyLogoTwo} alt="something" height={55} width={55} />
  </Icon>
)

export default Logo;