import React from "react";
import { Icon } from "@mui/material";
import kmlMark from '../images/kmlMark.png';

const kmlLogo = () => (
  <Icon style={{ fontSize: 65 }}>
    <img src={kmlMark} alt="something" height={65} width={65} />
  </Icon>
)

export default kmlLogo;