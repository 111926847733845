import * as React from 'react';
import { Paper, Grid, Box, Typography } from '@mui/material';
import pvoHero from "./images/pvoHero_small.jpg";

const titles = ["VIDEOGRAPHER", "EDITOR", "PHOTOGRAPHER", "PRODUCER"]

function HeroTop() {
  return (
    <Paper
      sx={{
        position: 'relative',
        backgroundColor: 'grey',
        color: '#fff',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: "50% 30%",
        backgroundImage: `url(${pvoHero})`,
        minWidth: { s: 600 },
        borderRadius: 0,
        padding: 3
      }}
    >
      {/* Increase the priority of the hero background image */}
      {/* {<img style={{ display: 'none' }} src={`url(${pvoHero})`} alt={`url(${pvoHero})`} />} */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          backgroundColor: 'rgba(0,0,0,.1)',
        }}
      />
      <Grid container justifyContent="flex-end">
        <Grid item md={8}>
          <Box
            sx={{
              position: 'relative',
              p: { xs: 1, md: 6 },
            }}
          >
            {
              titles.map((name, id) =>
                <Typography
                  key={id}
                  className='title'
                  component="h1"
                  variant="h3"
                  color="inherit"
                  gutterBottom
                  align='right'
                  sx={{
                    fontFamily: 'Raleway',
                    fontSize: { xs: "1.2em", s: "2em", md: "3em" },
                    paddingY: { s: "15px", md: "10px" }
                  }}
                >{name}</Typography>
              )
            }
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default HeroTop;