import React from 'react';
import ReactDOM from 'react-dom/client';
// import './theming/reset.css';
import './theming/index.css';
import RouteredApp from './RouteredApp';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouteredApp />
  </React.StrictMode>
);