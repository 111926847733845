import * as React from 'react';
import { useState, useLayoutEffect } from 'react';
import './theming/App.css';
import MyNavBar from './MyNavBar';
import { Divider, Fade, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import HeroTop from './HeroTop';
import { Container } from '@mui/system';
import Image from 'mui-image';
import Carousel from 'react-material-ui-carousel';

import Fab from '@mui/material/Fab';
import NavigationIcon from '@mui/icons-material/Navigation';

import snowCamping from "./images/snowCamping_web.jpg"
import paulNnu from "./images/paulNnu.png";

import photo1 from "./images/galleryImages/1.png";
import photo2 from "./images/galleryImages/2.png";
import photo3 from "./images/galleryImages/3.png";

import gTwoImg1 from "./images/galleryTwoImages/2013_nampaCarnival.jpg";
import gTwoImg2 from "./images/galleryTwoImages/2017_8747.jpg";
import gTwoImg3 from "./images/galleryTwoImages/pvo6224.jpg";

const galleryOne = [photo1, photo2, photo3]
const galleryTwo = [gTwoImg1, gTwoImg2, gTwoImg3]

const bioGraphs = [
  "I've been telling stories since running with a 30 pound beta camera and editing on a rack-mount AVID box. Nowadays that involves action cameras and HDSLRs, sliders, and a laptop wherever I go.",
  "I graduated from Northwest Nazarene University (NNU) in Mass Communication while honing my skills in the on-campus New Media department. I've continued a professional relationship with NNU as a freelancer in coordination with the marketing and media department.",
  "At Mission Aviation Fellowship (MAF) in Nampa, Idaho, I served as the multimedia producer, heading up all aspects of in-house video production and online video distribution, interfacing with media contractors, and collaborating with other international organizations, as well as managing interns.",
  "MAF has sent me on assignment for productions - going to MAF's operations in Haiti as a marketing and communications liaison during the 2016 Hurricane Matthew relief effort. Alongside my other responsibilities, I was a key architect on an international team that successfully implemented a digital asset manager to integrate international marketing and communications productions.",
  "In addition to my work with MAF and NNU, over the past 15 years I've partnered with musicians, schools, and other non-profits in Idaho to highlight their distinctiveness through video. I also earned an MBA to round out my skills as a freelancer in today's competitive market.",
  "Then! In 2018, my wife and I fulfilled a lifelong dream and joined the Peace Corps. On July 23rd of that year, we landed in Gaborone, Botswana, where we were placed with NGOs to help with HIV/AIDS awareness initiatives.",
  "In the following months I became involved with the organization Thusang Bana Centre and their founder Stanley Monageng, an inspiring HIV and AIDS activist. This resulted in me working alongside them to create the film Ke Mo Leseding, which documents Stanley as he walks 400 kilometers over the course of a 12-day span while raising awareness, showing fellow Batswana the effectiveness of current HIV treatment, and calling for additional destigmatization of people living with HIV.",
  "2020 swooped in, and COVID-19 forced the worldwide evacuation of Peace Corps volunteers. Within the span of an eventful week, we found ourselves in Washington state. It was during this time I finished production of Ke Mo Leseding, which went on to premiere at the Sotambe International Film Festival in Zambia, Africa. In collaboration with Thusang Bana Center, Peace Corps, and PEPFAR/ USAID, I am in the process of creating curriculum for the film to be used as an HIV/AIDS awareness tool in Botswana.",
  "If I'm not taking photos or video or editing one of the two, I will be spending my time with my lovely wife training for a marathon, playing board games with friends, or if I'm lucky, off snow-camping somewhere (and of course, taking photos!) on a snow-covered mountain."
]

const theFab = (
  <Fab className='fab' variant="extended" href='#top'
    sx={{
      position: "fixed",
      bottom: (theme) => theme.spacing(2),
      right: (theme) => theme.spacing(1),
      backgroundColor: '#9c27b0'
    }}
  >
    <NavigationIcon />
    To Top
  </Fab>
)

function App() {

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };
  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <MyNavBar />
      <HeroTop />

      <Box>
        <Fade
          in={scrollPosition !== 0}
          appear={true}
        >{theFab}</Fade>
      </Box>

      <Box
        paddingTop={2}
        sx={{
          backgroundColor: '#121a1c'
        }}
      >
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} >
              <Typography
                id="Bio"
                lineHeight={1.8}
                color="whitesmoke"
                sx={{
                  fontFamily: 'Libre Baskerville',
                  fontSize: { xs: ".7em", md: ".9em" },
                }}
              >
                {bioGraphs[0]}
                <br />
                {bioGraphs[1]}
              </Typography>
              <Box>
                <Image // https://stackoverflow.com/questions/61263669/does-material-ui-have-an-image-component
                  // item
                  src={paulNnu}
                  backgroundSize='fill'
                  sx={{
                    marginY: "8px",
                    maxWidth: { xs: 350, md: 450 },
                    // maxHeight: "100%",
                    borderRadius: "5px"
                  }}
                  alt="The house from the offer."
                />
              </Box>
              <Typography
                id="Bio"
                lineHeight={1.8}
                color="whitesmoke"
                sx={{
                  fontFamily: 'Libre Baskerville',
                  fontSize: { xs: ".7em", md: ".9em" },
                }}
              >
                {bioGraphs[2]}
                <br />
                {bioGraphs[3]}
                <br />
                {bioGraphs[4]}
                <br />
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                lineHeight={1.8}
                color="whitesmoke"
                sx={{
                  fontFamily: 'Libre Baskerville',
                  fontSize: { xs: ".7em", md: ".9em" },
                }}>
                {bioGraphs[5]}
                <br />
                {bioGraphs[6]}
                <br />
                {/* TODO: Image Carousel https://github.com/Moop204/react-mui-carousel */}
                {bioGraphs[7]}
                <br />
                {bioGraphs[8]}
              </Typography>
              {/* https://github.com/Learus/react-material-ui-carousel/blob/master/README.md */}
              <Carousel
                animation='fade'
                duration={400}
                interval={3500}
                // maxHeight={275}
                navButtonsAlwaysInvisible={true}
                indicators={false}
                sx={{ marginTop: -1.5 }} // oh what the heck.
              >
                {
                  galleryOne.map((item, i) =>
                    <Image // https://stackoverflow.com/questions/61263669/does-material-ui-have-an-image-component && https://github.com/TeamWertarbyte/material-ui-image#readme
                      duration={0}
                      animation={0}
                      src={item}
                      backgroundSize='cover'
                      sx={{
                        marginY: "18px",
                        maxWidth: { xs: 250, md: 400 },
                        maxHeight: { xs: 200, md: 400 }
                      }}
                      alt="The house from the offer."
                      key={i}
                    />
                  )}
              </Carousel>
            </Grid>
            <Image // https://stackoverflow.com/questions/61263669/does-material-ui-have-an-image-component
              id="Portfolio"
              src={snowCamping}
              backgroundSize='cover'
              sx={{
                marginTop: "8px",
                marginBottom: "12px",
                position: "relative",
                left: "4px"
              }}
              alt="The house from the offer."
            />
          </Grid>
          <Divider />
          <Box sx={{
            height: { xs: '30', sm: '45vh', md: '65vh' }
          }}>
            <iframe src="https://player.vimeo.com/video/218377774?h=9fd5c044fe&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="100%"
              height="100%"
              allow="autoplay; fullscreen; picture-in-picture"
              title="Paul O&amp;#039;Brien Reel">
            </iframe>
          </Box>
        </Container>
        <Typography
          lineHeight={1}
          marginY={2}
          color="whitesmoke"
          align='center'
          sx={{
            fontFamily: 'Libre Baskerville',
            fontSize: { sm: "1em", md: "2.5em" },
            margin: { sm: ".5em", md: "1em" }
          }}>
          <a href='https://vimeo.com/pvovideo'>Additional Video Productions</a>
        </Typography>
        <Divider />
        <Carousel animation='slide' duration={400} interval={3500} maxHeight={600} navButtonsAlwaysInvisible={true} indicators={false}>
          {
            galleryTwo.map((item, i) =>
              <Image
                duration={0}
                animation={0}
                src={item}
                backgroundSize='cover'
                sx={{
                  marginY: "8px",
                  maxHeight: { xs: 400, md: 800 },
                  width: '100%',
                }}
                alt="The house from the offer."
                key={i}
              />
            )}
        </Carousel>
      </Box>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </>
  );
}
export default App; 