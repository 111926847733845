import * as React from 'react';
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom'; // https://blog.logrocket.com/using-hooks-react-router/
import './theming/App.css';
// import MyNavBar from './MyNavBar';
// import HeroTop from './HeroTop';
import App from './App';
import KMLPrivacy from "./KmlPrivacy";
import RootLayout from './RootLayout';

const thisRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route index element={<App />} />
      <Route path="/kml-privacy-statement" element={<KMLPrivacy />} />
    </Route>
  )
)

function RouteredApp() {

  return (
    <RouterProvider router={thisRouter} />
  );
}
export default RouteredApp; 