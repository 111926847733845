import * as React from 'react';
import { AppBar, Button, Container, IconButton, MenuItem, Toolbar, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from './theming/PoLogo';
import KmlLogo from '../src/theming/kmlLogo'

const locations = ['Home', 'Bio', 'Portfolio'];

const typogSX = {
  ml: 2,
  display: { xs: 'none', md: 'flex' },
  fontFamily: 'Raleway',
  fontWeight: 700,
  letterSpacing: '.5rem',
  color: 'inherit',
  textDecoration: 'none',
  fontSize: "2.5em"
}

function MyNavBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const handleOpenNavMenu = (event) => { setAnchorElNav(event.currentTarget); };
  const handleCloseNavMenu = () => { setAnchorElNav(null); }

  return (
    <AppBar position="static"
      color='secondary'
      id='top'
    >
      <Container maxWidth="xl" disableGutters>
        <Toolbar disableGutters>
          <Box m={1.4}>
            <Typography
              variant="h6"
              noWrap
              component="a"
              sx={typogSX}
            >
              Paul V O'Brien
            </Typography>
          </Box>
          <Box sx={{ // for mobile/small browser screen
            flexGrow: 1, display: { xs: 'flex', md: 'none' }
          }}>
            <IconButton
              edge="start"
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' }
              }}
            >
              {locations.map((location) => ( // for narrow / mobile
                <MenuItem
                  href={"#" + location}
                  component="a"
                  key={location}
                  onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{location}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'Raleway',
              fontWeight: 700,
              letterSpacing: '.1rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            P O'Brien
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {locations.map((location) => (
              <Button
                href={"#" + location}
                key={location}
                onClick={handleCloseNavMenu}
                sx={{
                  ':hover': {
                    color: '#256fb2'
                  },
                  my: 2, color: '#5bb22d', display: 'block', fontFamily: 'Raleway', textAlign: 'center', fontSize: "1.3em"
                }}
              >
                {location}
              </Button>
            ))}

            <Box component="a"
              sx={
                {
                  // ml: .5,
                  scale: '85%'
                }
              }
              href='https://www.kemoleseding.com/' target='_blank'>
              <KmlLogo />
            </Box>
          </Box >
          <Box component="a" sx={{ marginTop: -1.5, mr: 1, display: { xs: 'flex', md: 'none' } }} href='https://www.kemoleseding.com/' target='_blank'>

            <KmlLogo />

          </Box>
          <Box sx={{ flexGrow: 0, mr: 2 }}>
            <Logo />
          </Box>
        </Toolbar >
      </Container >
    </AppBar >
  );
}

export default MyNavBar;